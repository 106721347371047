import { createContext } from 'react';
import { Callback } from 'amplitude-js';
import { TrackingEvent } from '../types/tracking-events.enum';
import { EventProperties } from '../types/tracking-event-properties.type';
import _ from 'lodash';

type TrackingContextType = {
    sendEvent: (
        event: TrackingEvent,
        eventProperties?: EventProperties,
        callback?: Callback,
    ) => void;
    debouncedSendEvent: _.DebouncedFunc<(event: TrackingEvent) => void>;
    setUserId: (userId: string | undefined | null) => void;
    clearUserProperties: () => void;
};
export const TrackingContext = createContext<TrackingContextType>({
    sendEvent: (
        event: TrackingEvent,
        eventProperties?: EventProperties,
        callback?: Callback,
    ): void => {},
    debouncedSendEvent: _.debounce((event: TrackingEvent) => {}, 1000),
    setUserId: (userId: string | undefined | null): void => {},
    clearUserProperties: (): void => {},
});
