import React from 'react';
import '@sensei/pi-component/dist/theme/browser-normalization.css';
import '@sensei/pi-component/dist/components/icon/LetterCircle/LetterCircle';
import ThemeProvider from '../src/context/ThemeProvider';
import { CssBaseline } from '@material-ui/core';
import { ErrorBoundary } from '../src/component/errorBoundary/ErrorBoundary';
import '../style/app.css';
import Auth0ProviderWrapper from '../src/auth/auth0-provider-wrapper';
import UserProvider from '../src/component/user-provider';
import SnackbarProvider from '../src/component/snackbar-provider';
import ApolloProviderWrapper from '../src/auth/apollo-provider-wrapper';
import { I18nextProvider } from 'react-i18next';
import i18n from '../src/i18n';
import TrackingProvider from '../src/component/tracking-provider';
import { initSentry } from '../src/util/sentry';

initSentry();

export default function App({
    Component,
    pageProps,
}: {
    Component: React.FC<any>;
    pageProps: object;
}) {
    return (
        <ErrorBoundary>
            <TrackingProvider>
                <I18nextProvider i18n={i18n}>
                    <ApolloProviderWrapper>
                        <Auth0ProviderWrapper>
                            <UserProvider>
                                <ThemeProvider>
                                    <CssBaseline />
                                    <SnackbarProvider>
                                        <Component {...pageProps} />
                                    </SnackbarProvider>
                                </ThemeProvider>
                            </UserProvider>
                        </Auth0ProviderWrapper>
                    </ApolloProviderWrapper>
                </I18nextProvider>
            </TrackingProvider>
        </ErrorBoundary>
    );
}
