import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './i18n/locales/translations.json';

const resources = {
    en: {
        translations: translationEN,
    },
};

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        // we init with resources
        resources,
        fallbackLng: 'en',
        debug: process.env.NODE_ENV === 'development',

        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ',',
        },

        react: {
            wait: true,
        },
    });

export default i18n;
