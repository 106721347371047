import { Route } from '../types/route.type';
import { StorageKeyEnum } from '../types/storage-key.enum';
import _ from 'lodash';

export const getReturnTo = (): Route | null => {
    const logoutReturnTo = localStorage.getItem(
        StorageKeyEnum.LOGOUT_RETURN_TO,
    );
    if (_.isString(logoutReturnTo)) {
        return JSON.parse(logoutReturnTo);
    } else {
        return null;
    }
};

export const getAccessToken = (): string | undefined => {
    try {
        if (typeof window !== 'undefined') {
            const auth0key = Object.keys({ ...localStorage }).filter((key) =>
                key.includes('auth0spajs'),
            );
            const auth0value = localStorage.getItem(auth0key[0]);
            if (auth0value) {
                const auth = JSON.parse(auth0value);
                return auth.body.access_token;
            }
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};

export const getUserId = (): string | undefined => {
    try {
        if (typeof window !== 'undefined') {
            const auth0key = Object.keys({ ...localStorage }).filter((key) =>
                key.includes('auth0spajs'),
            );
            const auth0value = localStorage.getItem(auth0key[0]);
            if (auth0value) {
                const auth = JSON.parse(auth0value);
                return auth.body.decodedToken.user.sub.split('auth0|')[1];
            }
        }
        return undefined;
    } catch (err) {
        return undefined;
    }
};
