import React, { useEffect, useRef } from 'react';
import { TrackingContext } from '../../context/TrackingContext';
import { AmplitudeClient, Callback } from 'amplitude-js';
import { TrackingEvent } from '../../types/tracking-events.enum';
import { useRouter } from 'next/router';
import { TrackedRoutesMap } from '../../types/tracked-routes.map';
import { RoutesEnum } from '../../types/routes.enum';
import { getUserId } from '../../util/local-storage';
import { EventProperties } from '../../types/tracking-event-properties.type';
import _ from 'lodash';

interface Props {
    children: React.ReactNode;
}

const TrackingProvider = ({ children }: Props) => {
    const router = useRouter();
    const route = useRef<string>();
    const previousUserID = useRef<string | null | undefined>();
    const amplitude = useRef<AmplitudeClient>();

    const isBrowser = () => typeof window !== 'undefined';
    if (isBrowser()) {
        // TODO Amplitude is turned off because it is not configured to handle the admin related events
        amplitude.current = undefined; //initAmplitude();
    }

    // Track the page views and update the userID if needed
    useEffect(() => {
        function updateUserId() {
            const userId = getUserId();
            if (
                amplitude.current &&
                userId &&
                previousUserID.current !== userId
            ) {
                previousUserID.current = userId;
                amplitude.current.setUserId(userId);
            }
        }

        if (isBrowser()) {
            if (!amplitude.current) {
                // TODO Amplitude is turned off because it is not configured to handle the admin related events
                amplitude.current = undefined; //initAmplitude();
            }
            const event = TrackedRoutesMap.get(router.pathname as RoutesEnum);
            if (
                router.pathname &&
                route.current !== router.pathname &&
                isBrowser() &&
                amplitude.current &&
                event
            ) {
                updateUserId();
                route.current = router.pathname;
                amplitude.current.logEvent(event, { path: router.pathname });
            }
        }
    }, [router.pathname]);

    const addPageEventProperty = (
        eventProperties?: EventProperties,
    ): EventProperties | undefined => {
        return route.current
            ? _.assign(eventProperties, { path: route.current })
            : eventProperties;
    };

    const setUserId = (userId: string | null | undefined): void => {
        if (amplitude.current) {
            previousUserID.current = userId;
            amplitude.current.setUserId(userId || null);
        }
    };

    const sendEvent = (
        event: TrackingEvent,
        eventProperties?: EventProperties,
        callback?: Callback,
    ): void => {
        if (amplitude.current) {
            amplitude.current.logEvent(
                event,
                addPageEventProperty(eventProperties),
                callback,
            );
        }
    };

    const debouncedSendEvent = _.debounce(
        (event: TrackingEvent) => sendEvent(event),
        1000,
    );

    const clearUserProperties = (): void => {
        if (amplitude.current) {
            amplitude.current.clearUserProperties();
        }
    };

    const contextValue = {
        sendEvent,
        debouncedSendEvent,
        setUserId,
        clearUserProperties,
    };

    return (
        <TrackingContext.Provider value={contextValue}>
            {children}
        </TrackingContext.Provider>
    );
};

export default TrackingProvider;
