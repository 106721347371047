import React, { useEffect, useState } from 'react';
import { UserContext } from '../../context/UserContext';
import { useLazyQuery } from '@apollo/client';
import { UserType } from '../../types/user.type';
import LoadingPage from '../loading';
import _ from 'lodash';
import { useAuth0 } from '@auth0/auth0-react';
import { GET_STAFF } from '../../services/staff.service';

interface Props {
    children: React.ReactNode;
}

const UserProvider = ({ children }: Props) => {
    const [userContext, setUserContext] = useState<Partial<UserType>>({
        id: undefined,
        isLoaded: false,
    });
    const { isAuthenticated, user, logout } = useAuth0();

    const handleGetUserDataResponse = (user: Partial<UserType>) => {
        if (user) {
            const userData = _.cloneDeep(user);
            userData.isLoaded = true;
            setUserContext(userData);
        } else {
            setUserContext({ isLoaded: true });
        }
    };

    const [getUserData, { called, loading, data }] = useLazyQuery<{
        me: Partial<UserType>;
    }>(GET_STAFF, {
        fetchPolicy: 'cache-first',
        onCompleted: (data) => handleGetUserDataResponse(data.me),
        onError: (error) => {
            logout({
                returnTo:
                    window.location.protocol +
                    '//' +
                    window.location.host +
                    '?info=message.notAllowedToLogIn',
            });
        },
    });

    const contextValue = {
        userContext,
        setUserContext,
    };

    useEffect(() => {
        if (
            !called &&
            !loading &&
            isAuthenticated &&
            !data &&
            _.isUndefined(userContext.id)
        ) {
            getUserData();
        } else {
            const userData = _.cloneDeep(user);
            setUserContext({ ...userData, isLoaded: true });
        }
    }, [
        called,
        data,
        getUserData,
        isAuthenticated,
        loading,
        user,
        userContext.id,
    ]);

    const getComponent = () => {
        if (called && loading && !data) {
            return <LoadingPage props={{ message: 'Loading user profile' }} />;
        } else {
            return children;
        }
    };

    return (
        <UserContext.Provider value={contextValue}>
            {getComponent()}
        </UserContext.Provider>
    );
};

export default UserProvider;
