import {
    ApolloClient,
    ApolloProvider,
    createHttpLink,
    from,
    InMemoryCache,
    ServerError,
} from '@apollo/client';
import React from 'react';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { formatISO } from 'date-fns/fp';
import { getAccessToken } from '../util/local-storage';
import { RoutesEnum } from '../types/routes.enum';

interface Props {
    children: React.ReactNode;
}

export const cache = new InMemoryCache();
const httpLink = createHttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
    credentials: 'include',
});

const errorLink = onError((error) => {
    const serverError = error.networkError as ServerError;
    if (serverError && serverError.statusCode && window) {
        if (serverError.statusCode === 401) {
            window.location.href =
                window.location.origin +
                RoutesEnum.LOGOUT +
                '?info=message.unauthorizedOperation';
        }
    }
});

const httpHeadersMiddlewareLink = setContext(
    async (_, { headers, ...context }) => {
        const accessToken = getAccessToken();
        return {
            headers: {
                ...headers,
                ...(accessToken
                    ? { Authorization: `Bearer ${accessToken}` }
                    : {}),
                ClientTime: formatISO(new Date()),
            },
            ...context,
        };
    },
);

const client = new ApolloClient({
    link: from([errorLink, httpHeadersMiddlewareLink, httpLink]),
    cache: cache,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'cache-and-network',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'network-only',
            errorPolicy: 'all',
        },
        mutate: {
            errorPolicy: 'all',
        },
    },
});
const ApolloProviderWrapper = ({ children }: Props) => {
    return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloProviderWrapper;
