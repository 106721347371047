import { gql } from '@apollo/client';

export const GET_STAFF = gql`
    query getStaff {
        me {
            email
            firstName
            id
            lastName
            permissions
            roles
        }
    }
`;

export const LOGOUT = gql`
    mutation Logout {
        logout
    }
`;
