import React, { useEffect, useState } from 'react';
import { SnackbarContext } from '../../context/SnanckarContext';
import ToastMessage from '../message/toast-message';
import { useAuth0 } from '@auth0/auth0-react';
import { SnackbarState } from '../../types/snackbar-context.type';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { RoutesEnum } from '../../types/routes.enum';

interface Props {
    children: React.ReactNode;
}

const SnackbarProvider = ({ children }: Props) => {
    const [snackbarContext, setSnackbarContext] = useState<SnackbarState>({
        open: false,
    });
    const contextValue = { snackbarContext, setSnackbarContext };
    const { isAuthenticated } = useAuth0();
    const { t } = useTranslation();
    const router = useRouter();
    const callback = () => {
        snackbarContext.callback && snackbarContext.callback();
        setSnackbarContext({ open: false });
    };

    useEffect(() => {
        if (
            typeof router.query['info'] === 'string' &&
            !router.pathname.includes(RoutesEnum.LOGOUT)
        ) {
            setSnackbarContext({
                open: true,
                message: router.query['info'],
            });
        }
    }, [router, setSnackbarContext]);

    return (
        <SnackbarContext.Provider value={contextValue}>
            {children}
            <ToastMessage
                {...snackbarContext}
                fullWidth={!isAuthenticated}
                callback={snackbarContext.callback ? callback : undefined}
                onClose={() => {
                    setSnackbarContext({ open: false });
                    snackbarContext.onClose && snackbarContext.onClose();
                }}
            >
                {typeof snackbarContext.message === 'string'
                    ? t(snackbarContext.message)
                    : snackbarContext.message}
            </ToastMessage>
        </SnackbarContext.Provider>
    );
};

export default SnackbarProvider;
