import { captureMessage, captureException } from '@sentry/node';

export const logger = {
    error(e: Error) {
        captureException(e);
    },
    info(message: string) {
        captureMessage(message);
    },
};
