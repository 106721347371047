import { makeStyles, Theme } from '@material-ui/core/styles';
import { sideBarWidth } from '../../constant/sidebar.constant';

const useDismissibleSnackbarStyles = makeStyles((theme: Theme) => ({
    root: {
        right: 0,
        padding: theme.spacing(0, 3),
        [theme.breakpoints.down('xs')]: {
            left: 0,
        },
    },
    contentWidth: {
        width: `calc(100% - ${sideBarWidth}px)`,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    fullWidth: {
        width: `100%`,
    },
    piAlertRoot: {
        width: '100%',
        padding: 0,
        minHeight: '76px',
    },
    piAlertAction: {
        paddingRight: theme.spacing(3),
        marginRight: 0,
    },
    piMessage: {
        textAlign: 'center',
        marginLeft: theme.spacing(5),
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
    },
    piAlertMessage: {
        width: '100%',
    },
    piErrorMessage: {
        width: 'auto',
    },
    retry: {
        color: 'white',
        borderColor: 'white',
        marginRight: theme.spacing(2),
    },
}));

export default useDismissibleSnackbarStyles;
