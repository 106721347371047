import React from 'react';
import { PiAlert, PiButton } from '@sensei/pi-component';
import { IconButton, Snackbar } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Color } from '@material-ui/lab';
import useDismissibleSnackbarStyles from '../../styles/snackbar/snackbar.style';

interface ToastMessageProps {
    children: React.ReactNode;
    severity?: Color;
    open: boolean;
    onClose: () => void;
    callback?: () => void;
    fullWidth?: boolean;
}

export default function ToastMessage(props: ToastMessageProps) {
    const classes = useDismissibleSnackbarStyles();
    const isError = props.severity === 'error';

    const getWidth = () => {
        return props?.fullWidth ? classes.fullWidth : classes.contentWidth;
    };
    return (
        <Snackbar
            classes={{ root: classes.root + ' ' + getWidth() }}
            open={props.open}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <PiAlert
                classes={{
                    root: classes.piAlertRoot,
                    message:
                        classes.piMessage +
                        ' ' +
                        (isError
                            ? classes.piErrorMessage
                            : classes.piAlertMessage),
                    action: classes.piAlertAction,
                }}
                severity={props.severity}
                action={
                    <>
                        {props.callback && isError && (
                            <PiButton
                                color="secondary"
                                className={classes.retry}
                                onClick={props.callback}
                            >
                                TRY AGAIN
                            </PiButton>
                        )}
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={props.onClose}
                        >
                            <Close fontSize="inherit" />
                        </IconButton>
                    </>
                }
            >
                {props.children}
            </PiAlert>
        </Snackbar>
    );
}
