import React, { Component } from 'react';
import { logger } from '../../services/logger.service';

type Props = {};

export class ErrorBoundary extends Component<Props> {
    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        logger.error(error);
        logger.info(errorInfo.componentStack);
    }

    render() {
        return this.props.children;
    }
}
