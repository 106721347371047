import React, { useContext } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { AppState } from '@auth0/auth0-react/dist/auth0-provider';
import { getUserId } from '../util/local-storage';
import { RoutesEnum } from '../types/routes.enum';
import { useRouter } from 'next/router';
import { TrackingContext } from '../context/TrackingContext';
import { TrackingEvent } from '../types/tracking-events.enum';

interface Props {
    children: React.ReactNode;
}

const Auth0ProviderWrapper = ({ children }: Props) => {
    const domain: string = process.env.NEXT_PUBLIC_AUTH0_DOMAIN || '';
    const clientId: string = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || '';
    const router = useRouter();
    const { sendEvent, setUserId } = useContext(TrackingContext);

    const onRedirectCallback = (appState: AppState) => {
        setUserId(getUserId());
        sendEvent(TrackingEvent.LOGGED_IN);
        router.push(appState?.returnTo ? appState?.returnTo : RoutesEnum.BASE);
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            cacheLocation={'localstorage'}
            redirectUri={
                typeof window !== 'undefined'
                    ? window.location.origin
                    : undefined
            }
            audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE || undefined}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWrapper;
